import style, { type Style } from '../style'

type Props = Style & {
  children: React.ReactNode
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p'
  className?: string
}

const Text = ({ children, className = '', as, ...rest }: Props) => {
  const Component: keyof JSX.IntrinsicElements = as

  return <Component className={`${style(rest)} ${className}`}>{children}</Component>
}

export default Text
