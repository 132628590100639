'use client'
'use client'

import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import ErrorLayout from '@hiretalk/components/layouts/error'

type Props = {
  error: Error & { digest?: string }
  reset: () => void
}

const GlobalErrorPage = ({ error, reset }: Props) => {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error)
  }, [error])
  return (
    <html>
      <body>
        <main className='min-h-screen bg-black'>
          <ErrorLayout handleReset={() => reset()} message={error.message} className='absolute inset-0' />
        </main>
      </body>
    </html>
  )
}

export default GlobalErrorPage
