import { type VariantProps, cva } from 'class-variance-authority'

const style = cva('', {
  variants: {
    size: {
      '12': 'text-xs',
      '14': 'text-sm',
      '16': 'text-md',
      '20': 'text-xl',
      '24': 'text-2xl',
      '30': 'text-3xl',
      '48': 'text-5xl',
    },
    weight: {
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },
    color: {
      black: 'text-black',
      white: 'text-white',
      yellow: 'text-primary-yellow',
      gray: 'text-[#9999A9]',
      green: 'text-secondary-green',
      red: 'text-secondary-red',
      brown: 'text-[#5D4C25]',
      sky: 'text-[#0070f3]',
    },
  },
  defaultVariants: {
    size: '16',
    weight: 'normal',
    color: 'white',
  },
})

export default style

export type Style = VariantProps<typeof style>
