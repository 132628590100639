import Button from '@hiretalk/components/elements/button'
import Text from '@hiretalk/components/elements/typographies/text'
import Image from 'next/image'

type Props = {
  message: string
  handleReset?: () => void
  className?: string
  color?: 'white' | 'black'
}

const ErrorLayout = ({ message, handleReset, className = '', color = 'white' }: Props) => {
  return (
    <div
      className={`flex min-h-full flex-col items-center justify-center gap-3 bg-secondary-red bg-opacity-5 py-4 text-center ${className}`}>
      <div className='relative h-60 w-48 lg:h-72 lg:w-64'>
        <Image
          src='/assets/images/logos/mascot2.png'
          alt='HireTalk Logo'
          fill
          className='object-contain object-center '
        />
      </div>

      <Text as='h1' color='red' weight='bold' size='30' className='text-xl lg:text-3xl'>
        Oops! Something went wrong!
      </Text>
      <Text as='p' color={color} className='w-full max-w-xl'>
        Message: {message}
      </Text>
      {handleReset && <Button onClick={handleReset}>Try Again</Button>}
    </div>
  )
}

export default ErrorLayout
