import { type VariantProps, cva } from 'class-variance-authority'

const style = cva(
  'text-center text-base font-semibold enabled:hover:scale-[0.98] transition-all duration-200 flex items-center gap-2 overflow-hidden cursor-pointer',
  {
    variants: {
      intent: {
        primary: 'border-transparent', //fill
        secondary: '', //just border
        tertiary: 'border-transparent', //just text
      },
      theme: {
        dark: '',
        light: '',
        gray: 'bg-secondary-gray-light',
        grayDark: ' bg-secondary-gray-dark',
      },
      width: {
        fit: 'w-fit',
        full: 'w-full justify-center items-center text-center',
      },
      radius: {
        none: 'rounded-none',
        full: 'rounded-full',
        medium: 'rounded-lg',
      },
      volume: {
        void: 'p-0 border-none',
        even: 'p-1.5',
        rect: 'px-3 py-1.5',
        base: 'px-6 py-1 border',
        large: 'px-8 py-1 border',
        compact: 'px-4 py-1 border',
        none: 'p-0',
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        theme: 'light',
        class: 'bg-secondary-orange text-white',
      },
      {
        intent: 'primary',
        theme: 'dark',
        class: 'bg-primary-purple-dark text-white',
      },
      {
        intent: 'secondary',
        theme: 'light',
        class: 'border-primary-yellow text-white',
      },
      {
        intent: 'secondary',
        theme: 'dark',
        class: 'border-primary-purple-dark text-primary-purple-dark',
      },
      {
        intent: 'tertiary',
        theme: 'dark',
        class: 'text-primary-purple-light',
      },
      {
        intent: 'tertiary',
        theme: 'light',
        class: 'text-primary-yellow',
      },
    ],
    defaultVariants: {
      intent: 'primary',
      theme: 'light',
      width: 'fit',
      radius: 'full',
      volume: 'base',
    },
  },
)

export default style

export type Style = VariantProps<typeof style>
