'use client'

import Image from 'next/image'
import style, { type Style } from './style'
import { toast } from 'react-hot-toast'

type Props = Style & {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit'
  isLoading?: boolean
  isDisabled?: boolean
  className?: string
}

const Button = ({
  children,
  isLoading,
  isDisabled,
  className = '',
  type = 'button',
  onClick = () => {},
  ...rest
}: Props) => {
  const LoadingSpinner = () => {
    if (!isLoading) return null
    return (
      <Image
        src='/assets/images/icons/loading.png'
        alt='loading'
        width={16}
        height={16}
        priority
        className='-ml-2 animate-spin'
      />
    )
  }

  return (
    <button
      type={type}
      disabled={isDisabled || isLoading}
      onClick={(event) => {
        toast.dismiss()
        onClick && onClick(event)
      }}
      className={`${style(rest)} ${className} ${isDisabled || isLoading ? 'grayscale' : ''}`}>
      <LoadingSpinner />
      {children}
    </button>
  )
}

export default Button
